@mixin carousel-button {
  background: rgba(73, 73, 119, 0.9);
  color: $off-white;
  height: 90px;
  width: 36px;
  font-size: 3rem;
  position: absolute;
  line-height: .1;
}

header {
  background: $primary;
  color: $light-gray;
  margin: 0 0 1rem;

  a {
    box-shadow: none;
  }

  h1 {
    font-family: 'Courgette', cursive;
  }

  .title-bar {
    background-color: $primary;
    padding: 1rem .5rem;
    margin: 0 0 .5rem 0;

    .title-bar-title{
      position: absolute;
      right: 12px;
      top: 8px;
    }
  }

  .topbar {
    padding: 10px 20px;

    @include breakpoint(small only) {
      position: absolute;
      width: 100%;
      background: $primary;
      margin: -8px 0 0;
      z-index: 300;
    }

    li {
      a {
        background: $primary;
        color: $light-gray;
        padding: 0.7rem;
      }
    }
  }
}

.key-points{
  text-align: center;

  &.grey {
    background: $medium-gray;
  }

  h4, .small-order-2 {
    margin: 0 0 2rem;
  }

  @include breakpoint(medium) {
    .left-text {
      padding: 0 0 0 2rem;
    }

    .right-text {
      padding: 0 2rem 0 0;
    }
  }
}

a {
  box-shadow: inset 0 -1px 0 0 rgba(73, 73, 119, 0.9), inset 0 -3px 0 0 rgba(43, 43, 70, .4);
}

.resume {
  li {
    margin: 0 0 0 13px;
  }

  .nested{
    list-style-type: circle;
  }
}

//TTI tti-examples
.tti-examples {
  figure {
    margin: 0 0 3rem;
  }

  figcaption {
    font-size: 14px;
  }
}

//Twenty-Twenty Comparison Section
.twentytwenty-wrapper {
  box-shadow: 2px 6px 4px 4px rgba(73, 73, 119, 0.4);
  margin: 0 0 3.5rem;
}

.twentytwenty-handle {
    border: 3px solid #a7a7c9;
}

.twentytwenty-right-arrow {
    border-left: 6px solid #a7a7c9;
}

.twentytwenty-left-arrow {
    border-right: 6px solid #a7a7c9;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
    background: rgba(118, 118, 171, 0.85);
    color: #e9e9e9;
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after {
    background: #a7a7c9;
}

.twentytwenty-horizontal .twentytwenty-handle:before {
    box-shadow: 0 3px 0 #a7a7c9, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:after {
    box-shadow: 0 -3px 0 #a7a7c9, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

// Owl Section
.owl-carousel {
  margin: 0 0 3.5rem;

  .owl-nav {
    position: absolute;
    top: 36%;
    width: 100%;

    button {
      &.owl-prev {
        @include carousel-button
        left: -10px;

        span {
          position: absolute;
          top: 43%;
          left: 25%;
        }
      }

      &.owl-next {
        @include carousel-button
        right: -10px;

        span {
          position: absolute;
          top: 43%;
          right: 25%;
        }
      }
    }

    @include breakpoint(small only) {
      button {
        &.owl-prev {
          left: 0;
        }

        &.owl-next {
          right: 0;
        }
      }
    }
  }



  @include breakpoint(small only) {
    .owl-nav {
      top: 20%;
    }
  }

  .owl-item {
    @include breakpoint(medium) {
      .figure {
        min-height: 350px;
      }
    }

    .small-image {
      padding: 5rem 0 0;
    }

    @include breakpoint(small only) {
        .small-image {
          padding: 2rem 0;
        }
    }

    figcaption {
      background: rgba(73, 73, 119, 0.9);
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px 20px;
      color: $off-white;
    }

    @include breakpoint(medium down) {
      figcaption {
        position: relative;
      }
    }

    img {
      width: auto;
      max-height: 666px;
      margin: auto;
    }

  }
}

@include breakpoint(small only) {
  .owl-carousel {
    margin: 3.5rem 0 1.5rem;
  }
}


footer {
  margin: 3rem 0 0;

  a {
    box-shadow: none;
  }

  .callout {
    margin: 0;
  }

  .footer-base {
    background-color: $primary;
    background-image: radial-gradient(closest-side, transparent 98%, rgba(0,0,0,.3) 99%),
    radial-gradient(closest-side, transparent 98%, rgba(0,0,0,.3) 99%);
    background-size:80px 80px;
    background-position:0 0, 40px 40px;
    padding: 26px;
    color: $medium-gray;

    .show-for-medium{
      text-align: right;
    }
  }
}
